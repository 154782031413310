<template>
  <AntRow>
    <AntCol
      :sm="{ span: 24, offset: 0 }"
      :md="{ span: 20, offset: 2 }"
      :lg="{ span: 16, offset: 4 }"
    >
      <FormTemplate
        :formFields="formFields"
        :onSubmit="onSubmit"
        buttonText="Create"
      />

      <AntAlert
        v-if="!!alertState.message"
        :type="alertState.type"
        :message="alertState.message"
        showIcon
      />
    </AntCol>
  </AntRow>
</template>

<script>
import { h, reactive } from 'vue'
import axios from 'axios'

import {
  Row as AntRow,
  Col as AntCol,
  Alert as AntAlert,
  Button as AntButton,
  Notification as antNotification,
  Message as antMessage,
} from 'ant-design-vue'

import {
  getUser,
  createUser,
  updateUser,
  deleteUser,
} from '@/api/tssw'

import { AWC_ENV_LIST } from '@/constants/index'
import { AGENT_CONFIG, CREATE_MEMBER_FORM_FIELDS } from '@/constants/tssw'

import FormTemplate from '@/components/AgentManagement/FormTemplate'

export default {
  name: 'CreateMember',
  components: {
    AntRow,
    AntCol,
    AntAlert,
    FormTemplate,
  },
  props: {
    awcEnv: String,
    requestResponseData: Object,
  },
  setup(props) {
    const requestResponseData = reactive(props.requestResponseData)
    const alertState = reactive({})

    const onSubmit = async (formState) => {
      const { userId, storageLocations } = formState
      const { awcEnv } = props

      const storedInTSSW = storageLocations.includes('tssw')
      const storedInAWC = storageLocations.includes('awc')

      delete formState.storageLocations

      let storableInAWC = false
      if (storedInTSSW) { // [tssw, awc] || [tssw]
        const { status, message } = await createUser({
          ...formState,
          env: awcEnv,
          balance: 0,
          isCreatedInAWC: storedInAWC,
        })

        if (status === 'success') {
          if (!storedInAWC) { // [tssw]
            alertState.type = 'success'
            alertState.message = `User ID: ${userId} created in TSSW successfully`
          } else {
            storableInAWC = true // [tssw, awc]
          }
        } else {
          alertState.type = 'error'
          alertState.message = message
        }
      }

      if (storableInAWC || (!storedInTSSW && storedInAWC)) { // [tssw, awc] || [awc]
        const requestData = {
          cert: AGENT_CONFIG[awcEnv].cert,
          agentId: AGENT_CONFIG[awcEnv].agentId,
          ...formState,
        }

        const URL = `${AWC_ENV_LIST[awcEnv].domain}/wallet/createMember`
        const { data: { data: awcData } } = await axios.post(`/proxy/${URL}`, requestData)

        if (awcData) {
          requestResponseData.url = URL
          requestResponseData.request = JSON.stringify(requestData)
          requestResponseData.response = JSON.stringify(awcData)

          if (awcData.status === '0000') {
            alertState.type = 'success'
            alertState.message = `User ID: ${userId} created successfully`

            if (!storedInTSSW) { // [awc], if user already existed in TSSW, update isCreatedInAWC = true
              const { status } = await getUser({ userId, env: awcEnv })
              if (status === 'success') {
                await updateUser({
                  userId,
                  env: awcEnv,
                  isCreatedInAWC: true,
                })
              }
            }
          } else {
            alertState.type = 'error'
            alertState.message = JSON.stringify(awcData)

            if (storedInTSSW) { // change [tssw, awc] into [tssw] if failed to create member in AWC
              await updateUser({
                userId,
                env: awcEnv,
                isCreatedInAWC: false,
              })

              const createMemberFailedNotikey = `open${Date.now()}`

              antNotification.error({
                message: 'Failed to create member in AWC',
                description: 'Do you want to delete user in TSSW?',
                duration: null,
                btn: [
                  h(
                    AntButton,
                    {
                      size: 'small',
                      onClick: () => antNotification.close(createMemberFailedNotikey),
                    },
                    'No',
                  ),
                  h(
                    AntButton,
                    {
                      type: 'primary',
                      size: 'small',
                      onClick: async () => {
                        const { status, message } = await deleteUser({ userId, env: awcEnv })
                        const messageKey = `delete-user-${Date.now()}`
                        if (status === 'success') {
                          antNotification.close(createMemberFailedNotikey)
                          antMessage.success({
                            key: messageKey,
                            content: `User ID: ${userId} deleted in TSSW successfully`,
                          })
                        } else {
                          antMessage.error({
                            key: messageKey,
                            duration: 5,
                            content: `Failed to delete member: ${message}`,
                          })
                        }
                      },
                    },
                    'Yes',
                  ),
                ],
                key: createMemberFailedNotikey,
              })
            }
          }
        }
      }
    }

    return {
      formFields: CREATE_MEMBER_FORM_FIELDS,
      alertState,
      onSubmit,
    }
  },
}
</script>

<style>
.ant-notification-notice-btn .ant-btn {
  margin-left: 8px;
}
</style>
